

















































import Vue from "vue";

const axios = require("axios");

export default Vue.extend({
  name: "HelloWorld",
  components: {},
  data: () => ({
    loading: false,
    showParsed: false,
    error: "",
    azToken: "",
    content: "",
    org: "flick2know",
    iterationPaths: [
      {
        id: 1,
        path: "Field_Assist\\FA Team 2\\Sprint 118 T2",
      },
    ],
  }),
  methods: {
    addIterationPath() {
      this.iterationPaths.push({ id: Date.now(), path: "Sample/Path" });
    },
    async generate() {
      try {
        this.error = "";
        if (!this.azToken || !this.org || this.iterationPaths.length == 0) {
          throw "token,org,iteration cannot be null/empty!";
        }
        this.loading = true;
        const paths = this.iterationPaths.map((value) => value.path);
        const config = {
          method: "get",
          url: `https://fa-azure-devops-api.azurewebsites.net/generate?token=${
            this.azToken
          }&org=${this.org}&iterationPaths=${JSON.stringify(paths)}`,
          headers: {},
        };

        const response = await axios(config);
        this.content = response.data;
      } catch (e) {
        console.error(e);
        this.error = `${e.toString()}\n${e.response?.data}`;
      } finally {
        this.loading = false;
      }
    },
    handleCopyCodeSuccess(code: any) {
      console.log(code);
    },
  },
});
