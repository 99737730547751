


































































import Vue from 'vue'

const axios = require('axios');


export default Vue.extend({
  name: 'Epic',
  components: {},
  data: () => ({
    snackbar: false,
    loading: false,
    error: '',
    message: '',
    azToken: '',
    ghToken: '',
    epicId: '',
  }),
  computed: {
    snackBarMsg: function () {
      return this.error != '' ? this.error : this.message;
    }
  },
  methods: {
    async generate() {
      try {
        this.error = '';
        this.message = '';
        if (!this.azToken || !this.ghToken || !this.epicId) {
          throw "azToken, ghToken, epicId cannot be null/empty!";
        }
        this.loading = true;
        const config = {
          method: 'get',
          url: `https://fa-azure-devops-api.azurewebsites.net/generator/epic?ghToken=${ this.ghToken }&epicId=${ this.epicId }&azToken=${ this.azToken }`,
          headers: {}
        };

        const response = await axios(config);
        this.epicId = "";
        console.log(response);
        this.message = 'The epic has been updated in docs. Please check after some time.';
      } catch (e) {
        console.error(e);
        this.error = e?.toString();
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },
  }
})
